/* Color palette */
$white: #ffffff;
$gray-100: #f8f8f8;
$gray-200: #f0f0f0;
$gray-300: #e0e0e0;
$gray-400: #adadad;
$gray-500: #929292;
$gray-600: #4f4f4f;
$gray-700: #a0a0a0;
$gray-800: #444444;
$gray-900: #666666;
$dark-gray-100: #7676764d;
$dark-gray-200: #c3c3c34d;
$black: #000000;

$light-cyan: #cde8ff;
$cyan: #26b8d3;
$light-blue: #00a3f5;
$blue: #465eff;
$blue-10: #000d60;
$blue-20: #001a98;
$blue-40: #2e48ec;
$blue-80: #bcc3ff;
$blue-95: #f0efff;
$dark-blue: #1b278d;
$dark-blue-200: #465eff4d;
$blue-60: #096cc8;
$brightBlue: #4285f4;
$chartBlue: #606eff;
$green: #42c98f;
$gren-100: #00a492;
$green-200: #1b8038;
$green-400: #e4f7e8;
$orange: #f59135;
$orange-900: #844000;
$brown: #d0934d;
$brown-200: #641616;
$red: #e24d5f;
$red-900: #7d1b15;
$yellow: #ffe579;
$yellow-200: #edda0c;
$yellow-300: #fcfc30;
$yellow-400: #f5ca09;

/* secondary */
$yellow-90: #eaea14;

$warning-bg-blue: #f2f8fc;
$warning-bg-red: #fcf2f2;

$primary: $yellow;
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-600;

// Body
$body-bg: $gray-100;
$body-color: $gray-600;
